import React from 'react'
import Navigation1 from '../Navigation/Navigation1'
import Footer from '../Footer/Footer'

const Flight = () => {
  return (
    <>
    <Navigation1 />
    <Footer />
    </>
  )
}

export default Flight